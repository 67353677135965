/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const CHANGE_TITLE = "CHANGE_TITLE";
export const ADD_BREADCRUMB = "ADD_BREADCRUMB";
export const REMOVE_BREADCRUMB = "REMOVE_BREADCRUMB";
export const UPDATE_WIZARD_STEP = "UPDATE_WIZARD_STEP";
export const SET_NOTIFICATIONS_DATA = "SET_NOTIFICATIONS_DATA";
export const REMOVE_NOTIFICATIONS_ITEM = "REMOVE_NOTIFICATIONS_ITEM";
export const SHOW_ADD_NOTIFICATIONS_BUTTON = "SHOW_ADD_NOTIFICATIONS_BUTTON";
export const SHOW_UPGRADE_BUTTON = "SHOW_UPGRADE_BUTTON";
export const SET_SENT_NOTIFICATIONS_DATA = "SET_SENT_NOTIFICATIONS_DATA";
export const SET_MAX_NOTIFICATION_PER_USER = "SET_MAX_NOTIFICATION_PER_USER";
export const SET_INACTIVE_PERIOD = "SET_INACTIVE_PERIOD";

export const SET_NOTIFICATION_TYPE = "SET_NOTIFICATION_TYPE";
export const UPDATE_CAROUSEL_PANEL_DATA = "UPDATE_CAROUSEL_PANEL_DATA";
export const UPDATE_SUBSCRIPTION = "SUBSCRIPTION_TYPE";
export const UPDATE_SENT_NOTIFICATIONS_COUNT =
  "UPDATE_SENT_NOTIFICATIONS_COUNT";
export const SET_INSTANCE_ID = "SET_INSTANCE_ID";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

export * from "./menu/actions";
export * from "./settings/actions";
