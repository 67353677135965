import * as firebase from 'firebase'

var firebaseConfig = {
    apiKey: "AIzaSyCGKq7-rudgifqad_G1pCtuzluuy2TrVRU",
    authDomain: "pushnotificationsplugin.firebaseapp.com",
    databaseURL: "https://pushnotificationsplugin.firebaseio.com",
    projectId: "pushnotificationsplugin",
    storageBucket: "pushnotificationsplugin.appspot.com",
    messagingSenderId: "521623713915",
    appId: "1:521623713915:web:319ab98e48f246effb8ade"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
