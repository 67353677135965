import { defaultLocale, localeOptions } from "../../constants/defaultValues";

import {
  ADD_BREADCRUMB,
  CHANGE_LOCALE,
  CHANGE_TITLE,
  REMOVE_BREADCRUMB,
  REMOVE_NOTIFICATIONS_ITEM,
  SET_MAX_NOTIFICATION_PER_USER,
  SET_INACTIVE_PERIOD,
  SET_NOTIFICATION_TYPE,
  SET_NOTIFICATIONS_DATA,
  SET_SENT_NOTIFICATIONS_DATA,
  SHOW_ADD_NOTIFICATIONS_BUTTON,
  SHOW_UPGRADE_BUTTON,
  UPDATE_CAROUSEL_PANEL_DATA,
  UPDATE_SUBSCRIPTION,
  UPDATE_WIZARD_STEP,
  UPDATE_SENT_NOTIFICATIONS_COUNT, SET_INSTANCE_ID,
} from "../actions";

const INIT_STATE = {
  locale:
    localStorage.getItem("currentLanguage") &&
    localeOptions.filter(
      (x) => x.id === localStorage.getItem("currentLanguage")
    ).length > 0
      ? localStorage.getItem("currentLanguage")
      : defaultLocale,
  title: "Push Notifications",
  breadCrumb: [{ path: "/app", title: "Home" }],
  wizardStep: undefined,
  notificationsData: { loading: true, data: [] },
  sentNotificationsData: [],
  showAddNotificationsButton: false,
  showUpgradeButton: false,
  maxNotificationPerUser: null,
  inactivePeriod: null,
  notificationType: null,
  carouselPanelData: [],
  subscription: {
    type: 1,
    loading: true,
    billingCycle: null
  },
  sentNotificationsCount: 0,
  instanceId: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case CHANGE_TITLE:
      return { ...state, title: action.payload };
    case ADD_BREADCRUMB:
      return { ...state, breadCrumb: action.payload };
    case UPDATE_WIZARD_STEP:
      return { ...state, wizardStep: action.payload };
    case REMOVE_BREADCRUMB:
      return {
        ...state,
        breadCrumbs: state.breadcrumbs.filter(
          (item) => item !== action.payload
        ),
      };
    case SET_NOTIFICATIONS_DATA:
      return {
        ...state,
        notificationsData: action.payload,
      };
    case REMOVE_NOTIFICATIONS_ITEM:
      return {
        ...state,
        notificationsData: {
          loading: state.notificationsData.loading,
          data: state.notificationsData.data.filter(
            (item) => item.id !== action.payload
          ),
        },
      };
    case SHOW_ADD_NOTIFICATIONS_BUTTON:
      return {
        ...state,
        showAddNotificationsButton: action.payload,
      };
    case SHOW_UPGRADE_BUTTON:
      return {
        ...state,
        showUpgradeButton: action.payload,
      };
    case SET_SENT_NOTIFICATIONS_DATA:
      return {
        ...state,
        sentNotificationsData: action.payload,
      };
    case SET_MAX_NOTIFICATION_PER_USER:
      return {
        ...state,
        maxNotificationPerUser: action.payload,
      };
      case SET_INACTIVE_PERIOD:
        return {
          ...state,
          inactivePeriod: action.payload,
        };
    case SET_NOTIFICATION_TYPE:
      return {
        ...state,
        notificationType: action.payload,
      };
    case UPDATE_CAROUSEL_PANEL_DATA:
      return {
        ...state,
        carouselPanelData: action.payload,
      };
    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case UPDATE_SENT_NOTIFICATIONS_COUNT:
      return {
        ...state,
        sentNotificationsCount: action.payload,
      };
    case SET_INSTANCE_ID:
      return {
        ...state,
        instanceId: action.payload
      }

    default:
      return { ...state };
  }
};
